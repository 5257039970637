.header-container[data-v-126db64e] {
  display: flex;
  justify-content: space-between;
  flex-direction: var(--126db64e-flexDirection);
}
.header-container .left-container > div[data-v-126db64e] {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: var(--126db64e-margin);
}
.header-container span[data-v-126db64e] {
  margin-right: 16px;
  font-weight: bolder;
}
.btn-container[data-v-126db64e] {
  display: flex;
  justify-content: end;
}
.confirm-container[data-v-126db64e] {
  display: flex;
  justify-content: space-around;
}
.interview-form[data-v-126db64e] label {
  margin-bottom: 0;
}
