.title[data-v-17033e2f] {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 15px;
  margin-bottom: 0;
}
.el-alert[data-v-17033e2f] {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 32px;
}
