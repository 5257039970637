.confirm-container[data-v-f7dd24f8] {
  display: flex;
  justify-content: space-around;
  padding: 16px 0px;
}
.confirm-container label[data-v-f7dd24f8] {
  margin-bottom: 0px;
}
.interview-form[data-v-f7dd24f8] label {
  margin-bottom: 0;
}
.interview-form[data-v-f7dd24f8] .el-form-item__label {
  font-weight: bolder;
  white-space: nowrap;
}
.interview-form-bottom[data-v-f7dd24f8] {
  overflow: scroll;
  height: var(--f7dd24f8-bottom_height);
  padding: 8px 0px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
